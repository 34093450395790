import { Controller } from "stimulus"
import Select2 from "select2/dist/js/select2.full"
import fillTemplate from 'es6-dynamic-template'
import ParamMap from '../helpers/param_map'

export default class extends Controller {
  connect() {
    let select2Options = {
      theme: 'bootstrap',
      dropdownCssClass: ':all:',
      width: 'auto',
      language: this.language(),
      placeholder: '선택해 주세요...',
    };
    if (this.element.dataset.select2Options) {
      select2Options = Object.assign(
        {},
        select2Options,
        JSON.parse(this.element.dataset.select2Options)
      )
    }

    console.log(select2Options);

    jQuery(this.element).select2(select2Options)
  }

  disconnect() {
    jQuery(this.element).select2('destroy')
  }

  language() {
    return {
      errorLoading: function () {
        return '결과를 불러올 수 없습니다.'
      },
      inputTooLong: function (args) {
        var overChars = args.input.length - args.maximum

        var message = '너무 깁니다. ' + overChars + ' 글자 지워주세요.'

        return message
      },
      inputTooShort: function (args) {
        var remainingChars = args.minimum - args.input.length

        var message = '너무 짧습니다. ' + remainingChars + ' 글자 더 입력해주세요.'

        return message
      },
      loadingMore: function () {
        return '불러오는 중…'
      },
      maximumSelected: function (args) {
        var message = '최대 ' + args.maximum + '개까지만 선택 가능합니다.'

        return message
      },
      noResults: function () {
        return '결과가 없습니다.'
      },
      searching: function () {
        return '검색 중…'
      },
      removeAllItems: function () {
        return '모든 항목 삭제'
      }
    }
  }
}