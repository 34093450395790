import { Controller } from 'stimulus'
import ufo from '../helpers/ufo_app'
export default class extends Controller {
  static targets = ['form']

  submit(event) {
    event.preventDefault()
    let formUrl = event.currentTarget.dataset.formUrl
    let mobileAppFormUrl = event.currentTarget.dataset.mobileAppUrl
    let mobileAppHandler = event.currentTarget.dataset.mobileAppHandler
    let mobileAppProvider = event.currentTarget.dataset.mobileAppProvider

    let currentUrl = (ufo.isApp() && ufo.canHandle(mobileAppHandler) && mobileAppFormUrl ? mobileAppFormUrl : formUrl)

    if (ufo.isApp() && ufo.canHandle('startSocialSignIn') && mobileAppProvider ) {
      if (mobileAppProvider == 'facebook' && window.webkit && formUrl) {
        this.formTarget.setAttribute('action', formUrl)
        this.formTarget.submit()
      } else {
        ufo.startSocialSignIn(mobileAppProvider)
      }
    } else if(ufo.isApp() && mobileAppFormUrl && mobileAppProvider != 'facebook') {
      window.location.href = mobileAppFormUrl
    } else if (formUrl) {
      this.formTarget.setAttribute('action', formUrl)
      this.formTarget.submit()
    }
  }
}